<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">

    </PageMenu>

    <div class="page-with-menu">
      <b-card title="SMTP">
        <b-card-text>
          <FormTemplate v-if="form" :form="form">
            <b-row>
              <b-col col lg="12" md="12" sm="12">
                <b-form-group class="label-for-xl" label="Name">
                    <b-form-input
                        type="text" class="form-control form-control-xl"
                        placeholder="enter name"
                        v-model="form.data.name"
                        :state="form.states.name"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.name }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col col lg="6" md="6" sm="12">
                <b-form-group class="label-for-xl" label="Hostname">
                    <b-form-input
                        type="text" class="form-control form-control-xl"
                        placeholder="enter hostname"
                        v-model="form.data.host"
                        :state="form.states.host"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.host }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col col lg="6" md="6" sm="12">
                <b-form-group class="label-for-xl" label="Port">
                    <b-form-input
                        type="text" class="form-control form-control-xl"
                        placeholder="enter port"
                        v-model="form.data.port"
                        :state="form.states.port"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.port }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col col lg="6" md="6" sm="12">
                <b-form-group class="label-for-xl" label="Username">
                    <b-form-input
                        type="text" class="form-control form-control-xl"
                        placeholder="enter username"
                        v-model="form.data.username"
                        :state="form.states.username"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.username }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col col lg="6" md="6" sm="12">
                <b-form-group class="label-for-xl">
                    <label>
                      Password
                      <b-link v-if="passwordInputType === 'text'" @click="passwordInputType='password'">
                        <BIconEye/>
                      </b-link>
                      <b-link v-if="passwordInputType === 'password'" @click="passwordInputType='text'">
                        <BIconEyeSlash/>
                      </b-link>
                    </label>
                    <b-form-input
                        :type="passwordInputType" class="form-control form-control-xl"
                        placeholder="enter password"
                        v-model="form.data.password"
                        :state="form.states.password"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.password }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col col lg="6" md="6" sm="12">
                <b-form-group class="label-for-xl" label="From name">
                    <b-form-input
                        type="text" class="form-control form-control-xl"
                        placeholder="enter from name"
                        v-model="form.data.fromName"
                        :state="form.states.fromName"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.fromName }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col col lg="6" md="6" sm="12">
                <b-form-group class="label-for-xl" label="From email">
                    <b-form-input
                        type="text" class="form-control form-control-xl"
                        placeholder="enter from email"
                        v-model="form.data.fromEmail"
                        :state="form.states.fromEmail"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.fromEmail }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col col lg="6" md="6" sm="12">
                <b-form-group class="label-for-xl" label="Reply to name">
                    <b-form-input
                        type="text" class="form-control form-control-xl"
                        placeholder="enter reply to name"
                        v-model="form.data.replyToName"
                        :state="form.states.replyToName"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.replyToName }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col col lg="6" md="6" sm="12">
                <b-form-group class="label-for-xl" label="Reply to email">
                    <b-form-input
                        type="text" class="form-control form-control-xl"
                        placeholder="enter reply to email"
                        v-model="form.data.replyToEmail"
                        :state="form.states.replyToEmail"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.replyToEmail }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </FormTemplate>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';
import {BIconEye,BIconEyeSlash} from 'bootstrap-vue';
export default {
    components: {
      PageMenu,
      FormTemplate,
      BIconEye,BIconEyeSlash
    },
    data(){
      return {
        page: {
            title: "Email Settings",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Emails",
                url: this.$router.resolve({name: "emails"}).href
            },
            {
              title: "Email Settings"
            }
        ],
        form: null,
        passwordInputType: "password",
        smtp: null
      }
    },
    methods: {

    },
    mounted(){
      this.$emit('set-sidebar',{active: "emails"});

      this.$api.get('emails/settings').then((res) => {
        let {smtp} = res.data;
        this.smtp = smtp;

        this.form = new Form({
          name: smtp.name,
          host: smtp.host,
          port: smtp.port,
          username: smtp.username,
          password: smtp.password,
          fromEmail: smtp.fromEmail,
          fromName: smtp.fromName,
          replyToName: smtp.replyToName,
          replyToEmail: smtp.replyToEmail
        },{
          onSubmit: (form) => {
            return this.$api.put(`emails/settings/smtp/${this.smtp.id}`,{
              data: {
                name: form.get("name"),
                host: form.get("host"),
                port: form.get("port"),
                username: form.get("username"),
                password: form.get("password"),
                fromEmail: form.get("fromEmail"),
                fromName: form.get("fromName"),
                replyToName: form.get("replyToName"),
                replyToEmail: form.get("replyToEmail")
              }
            });
          }
        });
      });
    },
};
</script>
